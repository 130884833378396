/*
fonts
*/
@font-face {
  font-family: ABBvoice;
  src: url(assets/fonts/ABBvoice_W_Rg.woff);
  font-weight: normal;
}
@font-face {
  font-family: ABBvoice-Bold;
  src: url(assets/fonts/ABBvoice_W_Bd.woff);
  font-weight: bold;
}
@font-face {
  font-family: ABBvoice-Semibold;
  src: url(assets/fonts/ABBvoice_W_Md.woff);
  font-weight: normal;
}
@font-face {
  font-family: ABBvoice-Light;
  src: url(assets/fonts/ABBvoice_W_Lt.woff);
  font-weight: normal;
}
@font-face {
  font-family: ABBvoice-Light;
  src: url(assets/fonts/ABBvoice_W_Lt.woff);
  font-style: normal;
  font-weight: 100;
}

@font-face {
  font-family: Averta;
  src: url(assets/fonts/Averta-Regular.woff);
  font-weight: normal;
}
@font-face {
  font-family: Averta-Bold;
  src: url(assets/fonts/Averta-Bold.woff);
  font-weight: bold;
}
@font-face {
  font-family: Averta-Extra-Bold;
  src: url(assets/fonts/Averta-Extra-Bold.woff);
  font-weight: bolder;
}
@font-face {
  font-family: Averta-Semibold;
  src: url(assets/fonts/Averta-Semibold.woff);
  font-weight: normal;
}
@font-face {
  font-family: Averta-Light;
  src: url(assets/fonts/Averta-Light.woff);
  font-weight: normal;
}

@font-face {
  font-family: icon-font;
  src: url(assets/fonts/busch-jaeger-icons.ttf);
  font-weight: normal;
}

html,
body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  font-family: 'ABBvoice', 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth !important;
  background: transparent;
}

#root {
  background: transparent;
  margin: 0 auto;
  position: relative;
  height: 100%;
}

#content {
  height: 100vh;
  width: 100vw;
  background: #fff;
}

.app {
  height: 100%;
}

a,
a:visited,
a:hover {
  outline: none;
  text-decoration: none !important;
}

.chatbotAbb .rsc-container,
.chatbotBje .rsc-container,
.chatbotStriebel .rsc-container {
  width: 100% !important;
  height: 100% !important;
}

.chatbotAbb .rsc-content,
.chatbotBje .rsc-content,
.chatbotStriebel .rsc-content {
  height: 100% !important;
}

.chatbotAbb .rsc-ts-image-container,
.chatbotBje .rsc-ts-image-container,
.chatbotStriebel .rsc-ts-image-container {
  display: none;
}

.chatbotAbb .rsc-cs .rsc-ts-bubble,
.chatbotBje .rsc-cs .rsc-ts-bubble,
.chatbotStriebel .rsc-cs .rsc-ts-bubble {
  border-radius: 0 20px 20px 0;
  box-shadow: none;
  color: #000;
  display: inline-block;
  font-size: 14px;
  max-width: 50%;
  margin: -8px 0px 10px;
  overflow: hidden;
  position: relative;
  padding: 12px;
  transform-origin: left top;
}

.chatbotAbb .rsc-ts-bot .rsc-ts-bubble,
.chatbotAbb .rsc-cs .rsc-ts-bubble {
  box-shadow: none;
  background: #d2d2d2;
  color: #000;
  border-radius: 0 20px 20px 0;
}

.chatbotBje .rsc-ts-bot .rsc-ts-bubble,
.chatbotBje .rsc-cs .rsc-ts-bubble {
  box-shadow: none;
  background: #efefef;
  color: #000;
  border-radius: 0 20px 20px 0;
}

.chatbotStriebel .rsc-ts-bot .rsc-ts-bubble,
.chatbotStriebel .rsc-cs .rsc-ts-bubble {
  box-shadow: none;
  background: rgba(0, 134, 57, 0.1);
  color: #000;
  border-radius: 0 20px 20px 0;
}

.chatbotAbb .rsc-ts-user .rsc-ts-bubble {
  box-shadow: none;
  background: #000;
  color: #fff;
  border-radius: 20px;
  margin-right: 10px;
}

.chatbotBje .rsc-ts-user .rsc-ts-bubble {
  box-shadow: none;
  background: #009fe3;
  color: #fff;
  border-radius: 20px;
  margin-right: 10px;
}

.chatbotStriebel .rsc-ts-user .rsc-ts-bubble {
  box-shadow: none;
  background: #008639;
  color: #fff;
  border-radius: 20px;
  margin-right: 10px;
}
.chatbotAbb .rsc-os-option,
.chatbotBje .rsc-os-option,
.chatbotStriebel .rsc-os-option {
  animation: 0.3s ease 0s 1 normal forwards running gZmgDU;
  cursor: pointer;
  display: inline-block;
  margin: 2px;
  transform: scale(0);
}

.chatbotAbb .rsc-os-option-element,
.chatbotBje .rsc-os-option-element,
.chatbotStriebel .rsc-os-option-element {
  background: rgb(110, 72, 170);
  border: 0px;
  border-radius: 22px;
  box-shadow: rgb(0 0 0 / 15%) 0px 1px 2px 0px;
  color: rgb(255, 255, 255);
  display: inline-block;
  font-size: 14px;
  padding: 12px;
}

.chatbotAbb .rsc-os-option-element {
  border: 1px solid #000;
  box-shadow: none;
  background: #fff;
  color: #000;
  margin-bottom: 5px;
}

.chatbotBje .rsc-os-option-element {
  border: 1px solid #009fe3;
  box-shadow: none;
  background: #fff;
  color: #000;
  margin-bottom: 5px;
}

.chatbotStriebel .rsc-os-option-element {
  border: 1px solid #008639;
  box-shadow: none;
  background: #fff;
  color: #000;
  margin-bottom: 5px;
}

.chatbotAbb .rsc-cs,
.chatbotBje .rsc-cs,
.chatbotStriebel .rsc-cs {
  box-shadow: none;
  background: transparent;
  justify-content: start;
  margin: 0;
  padding: 16px 0;
}

.lauraSpeech {
  position: absolute;
  top: -8px;
  right: 8px;
  z-index: 1200;
  transform-origin: center center;
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
