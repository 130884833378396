.timeline {
  position: relative;
}

.timline h1 {
  font-size: 200%;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-weight: 400;
}

.timeline h1,
.timeline h2,
.timeline h3 {
}

.timeline::before {
  content: '';
  background: #b4b4b4;
  width: 2px;
  height: 100%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: -5;
}

.timeline-item {
  width: 100%;
  margin-bottom: 100px;
  z-index: 100;
}
.timeline-item::after {
  content: '';
  display: block;
  clear: both;
}

.timeline-content {
  position: relative;
  width: 35%;
  border-radius: 4px;
  background: #f0f0f0;
  z-index: -1;
  border: 2px solid #f0f0f0;
}

.timeline-content.left {
  padding: 2rem;
}

.timeline-content.right {
  padding: 2rem;
  float: right;
}

.timeline-item p {
  margin-top: 0px;
  margin-bottom: 0px;
}

.timeline-list:first-child {
  padding: 10vh 0px;
}

.timeline-list:last-child {
  padding: 10vh 0px;
  margin-bottom: 0px;
}

.timeline-content img {
  width: 10rem;
  text-align: right;
  display: block;
  margin-top: 3em;
  cursor: pointer;
}

.timeline-content img.ms-auto {
  margin-left: auto !important;
  transform: scale(1.5) translate(-2em, 2em);
  transition: transform 150ms ease-in-out;
}

.timeline-content img.ms-md-0 {
  transform: scale(1.5) translate(2em, 2em);
  transition: transform 150ms ease-in-out;
}

.timeline-content img.ms-auto:hover {
  transform: scale(1.6) translate(-2em, 2em);
  transition: transform 150ms ease-in-out;
}

.timeline-content img.ms-md-0:hover {
  transform: scale(1.6) translate(2em, 2em);
  transition: transform 150ms ease-in-out;
}

.timeline-dot {
  position: absolute;
  left: calc(50% - (70px / 2));
  translate: 0px -20px;
  color: white;
  background-color: #000;
  font-size: 1.4em;
  justify-content: center;
  margin-top: 0px;
  display: flex;
  align-items: center;
  font-family: Averta-Semibold;
  z-index: 200;
  border-radius: 100px;
  width: 70px;
  height: 70px;
}

@media screen and (max-width: 768px) {
  .timeline::before {
    left: 50px;
  }

  .timeline .timeline-dot {
    left: 15px;
  }

  .timeline .timeline-content {
    max-width: 100%;
    width: auto;
  }

  .timeline-content.left,
  .timeline-content.right {
    padding-left: 20%;
  }
  .timeline-content.right {
    float: initial;
  }
  .timeline-content.left {
    padding: 2rem 5% 2rem 20%;
  }

  .timeline-item {
    width: 100%;
    margin-bottom: 7rem;
  }

  .timeline-content img.ms-0 {
    transform: scale(1.5) translate(2em, 2em) !important;
  }
}

.modal-body img {
  max-height: 80vh;
  max-width: 80vw;
}

.modal-body {
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  user-select: none;
}

.modal-dialog {
  padding: 2rem;
}

.scrollIndicator {
  background: #005c24;
  width: 10px;
  position: absolute;
  transform: translateX(-50%);
  border-radius: 0px 0px 100px 100px;
  left: 66px;
}

@media (min-width: 600px) {
  .scrollIndicator {
    left: 74px;
  }
}

@media (min-width: 768px) {
  .scrollIndicator {
    left: 50%;
  }
}

@media (min-width: 992px) {
  .timeline-content img {
    width: 13rem !important;
  }
}
